import {Component, OnInit, Input, HostListener, ViewChild, AfterViewChecked, Output, EventEmitter} from '@angular/core';
import {AwsAuthenticatedSdkService} from '@synthroneApp/services/aws-authenticated-sdk/aws-authenticated-sdk.service';
import {MessagesService} from '@angular/synthrone-design/services/messages/messages.service';
import {ApiService} from '@angular/synthrone-design/services/api/api.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {CookieService} from '@angular/synthrone-design/services/cookie/cookie.service';
import {Router} from '@angular/router';
import {ErrorComponent} from '@angular/synthrone-design/components/dialogs/error/error.component';
import {VirtualStorageService} from '@angular/synthrone-design/services/virtual-storage/virtual-storage.service';
import {PermissionsService} from '@angular/synthrone-design/services/permissions/permissions.service';
import {ConfirmationComponent} from '@angular/synthrone-design/components';
import {FormControl} from '@angular/forms';
import {MatTable} from '@angular/material/table';
import {GetObjectCommand, S3Client} from '@aws-sdk/client-s3/';
import {InvokeCommand, LambdaClient} from '@aws-sdk/client-lambda';
import { Buffer } from 'buffer/';

export interface MouseEvent {
    rowId: number;
    colId: number;
    cellsType: string;
}

export interface Element {
    type: string;
    platform: string;
    ean: string;
    fpc: string;
    subCategory: string;
    brand: string;
    name: string;
    powersku: string;
    rpc: string;
    url: string;
    row: number;
    rowId: string;
    previewUrl: string;
}

@Component({
    selector: 'import-table',
    templateUrl: './import-table.component.html',
    styleUrls: ['./import-table.component.scss']
})
export class ImportTableComponent implements OnInit, AfterViewChecked {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('table') set table(table: MatTable<any>) {
        this.isTable.emit(!(this.isLoading || this.isTableLoading || !this.brands || !this.brands.length || !this.subCategories || !this.subCategories.length));
    }
    @Input() platformSlug;
    @Input() platformType;
    @Input() jobDetails;
    @Input() isActive;
    @Output() isJobJsonEditFinished = new EventEmitter<boolean>();
    @Output() isPlatformDataEditFinished = new EventEmitter<boolean>();
    @Output() isRetry = new EventEmitter<boolean>();
    @Output() isTable = new EventEmitter<boolean>();
    isLoading = true;
    refreshUrl = false;
    isTableLoading = true;
    trackerProducts = 0;
    newProducts = 0;
    productsToConfirm = 0;
    notFoundProducts = 0;
    subCategories = []
    subCategoriesFiltered: any[];
    public subCategoriesFilterCtrl: FormControl = new FormControl();
    brands = []
    brandsFiltered: any[];
    public brandsFilterCtrl: FormControl = new FormControl();
    displayedColumns: string[] = ['type', 'platform', 'ean', 'fpc', 'subCategory', 'brand', 'name', 'powersku', 'rpc', 'url', 'actions'];
    dataSource: any;
    tableMouseDown: MouseEvent;
    tableMouseUp: MouseEvent;
    FIRST_EDITABLE_ROW = 0;
    LAST_EDITABLE_ROW: number;
    FIRST_EDITABLE_COL = 4;
    LAST_EDITABLE_COL = 5;
    newCellValue: string;
    jsonResult: any;
    allResults: number;
    updatedJSON = [];
    selectedValues = {
        'brand': '',
        'subCategory': ''
    };
    copiedValues: any;
    selectedCellsState: boolean[][] = [];
    canRetry = false;
    isMultiSelection = false;

    constructor(
        protected awsService: AwsAuthenticatedSdkService,
        private messageService: MessagesService,
        protected api: ApiService,
        private dialog: MatDialog,
        private _liveAnnouncer: LiveAnnouncer,
        private cookieService: CookieService,
        private router: Router,
        private virtualService: VirtualStorageService,
        public permissionService: PermissionsService,
    ) {
    }

    ngOnInit(): void {
        this.canRetry = this.checkRetryPermission();
        this.platformType = this.platformType ? this.platformType : this.jobDetails.platformType;
        this.refreshUrl = this.jobDetails.refreshUrl ? this.jobDetails.refreshUrl : this.refreshUrl;
        if (this.jobDetails && this.jobDetails.stage.toString() === '3') {
            this.FIRST_EDITABLE_COL = 7;
            this.LAST_EDITABLE_COL = 8;
        }
        this.subCategories = this.virtualService.getItem('cst-filter-subCategory');
        if (!this.subCategories) {
            this.api.makeRequest('api_v1_subcategory_list', {}, 'tracker3').subscribe(
                (subCategories: any) => {
                    this.subCategories = subCategories
                    this.subCategories = this.subCategories.filter(item => !item.deletedAt);
                    this.subCategories = this.subCategories.sort((a, b) => a.name.localeCompare(b.name))
                    this.subCategoriesFiltered = [...this.subCategories];
                    this.subCategoriesFilterCtrl.valueChanges.subscribe((subCategoriesFilter) => {
                        this.subCategoriesFiltered = this.subCategories.filter(c => c.name.toLowerCase().trim().indexOf(subCategoriesFilter.toLowerCase().trim()) > -1)
                    });
                },
                (error: any) => {
                    this.messageService.showSnack(error, 'error')
                }
            );
        } else {
            this.subCategories = this.subCategories.filter(item => !item.deletedAt);
            this.subCategories = this.subCategories.sort((a, b) => a.name.localeCompare(b.name))
            this.subCategoriesFiltered = [...this.subCategories];
            this.subCategoriesFilterCtrl.valueChanges.subscribe((subCategoriesFilter) => {
                this.subCategoriesFiltered = this.subCategories.filter(c => c.name.toLowerCase().trim().indexOf(subCategoriesFilter.toLowerCase().trim()) > -1)
            });
        }
        this.brands = this.virtualService.getItem('cst-filter-brand');
        if (!this.brands) {
            this.api.makeRequest('api_v1_brand_list', {'filters[isCompetitor]': 0}, 'tracker3').subscribe(
                (brands: any) => {
                    this.brands = brands;
                    this.brands = this.brands.filter(brand => !brand['isCompetitor'] && !brand.deletedAt)
                    this.brands = this.brands.sort((a, b) => a.name.localeCompare(b.name))
                    this.brandsFiltered = [...this.brands];
                    this.brandsFilterCtrl.valueChanges.subscribe((brandsFilter) => {
                        this.brandsFiltered = this.brands.filter(c => c.name.toLowerCase().trim().indexOf(brandsFilter.toLowerCase().trim()) > -1)
                    });
                },
                (error: any) => {
                    this.messageService.showSnack(error, 'error')
                }
            );
        } else {
            this.brands = this.brands.filter(brand => !brand['isCompetitor'] && !brand.deletedAt)
            this.brands = this.brands.sort((a, b) => a.name.localeCompare(b.name))
            this.brandsFiltered = [...this.brands];
            this.brandsFilterCtrl.valueChanges.subscribe((brandsFilter) => {
                this.brandsFiltered = this.brands.filter(c => c.name.toLowerCase().trim().indexOf(brandsFilter.toLowerCase().trim()) > -1)
            });
        }
        if (this.jobDetails) {
            this.platformSlug = this.jobDetails['platform'] ? this.jobDetails['platform'] : this.platformSlug;
            this.getS3Object().then(response => {
                if (response.errorType) {
                    this.messageService.showSnack(response.errorMessage, 'error')
                } else {
                    response.Body.transformToString().then((result) => {
                      this.jsonResult = JSON.parse(result);
                      this.allResults = this.jsonResult.length;
                      this.prepareData();
                      this.LAST_EDITABLE_ROW = this.dataSource['filteredData'].length - 1;
                      this.isLoading = false;

                      if (this.notFoundProducts === this.allResults && this.jobDetails.stage.toString() === '2') {
                          setTimeout(() => {
                              this.dialog.open(ErrorComponent, {
                                  data: {
                                      message: 'No results found on tracker and browser. Job will be aborted!'
                                  }
                              }).afterClosed().subscribe((response) => {
                                  this.abort();
                              });
                          }, 1000);
                      }
                    });
                }
            })
        }
    }

    ngAfterViewChecked() {
        if (this.dataSource && !this.dataSource.sort) {
            this.dataSource.sort = this.sort;
        }
    }

    checkRetryPermission() {
        const token = this.cookieService.getCookie('appToken');
        const encodedData = atob(token.split('.').shift());
        const decodedData = JSON.parse(encodedData);
        return (decodedData && decodedData.superadmin && decodedData.superadmin === true);
    };

    getS3Object(): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const params = {
                    Bucket: this.awsService.getBucket(),
                    Key: this.jobDetails.user_id ? `explorer/jobs/${this.jobDetails.user_id}/${this.jobDetails.job_id}/data.json` : `explorer/jobs/${this.jobDetails.userId}/${this.jobDetails.jobId}/data.json`
                };

                this.awsService.getInstance().subscribe(config => {
                    const s3 = new S3Client(config);

                    s3.send(
                        new GetObjectCommand(params), function (error, data) {
                            if (error) {
                                return reject(error)
                            }
                            return resolve(data);
                    });
                });
            })
    };

    download(type = 'import') {
        let endpoint = 'jobXlsxImportUrl';

        if (type === 'notFound' && this.jobDetails.stage.toString() === '2') {
            endpoint = 'exportBrowserProductsNotFound';
        } else if (type === 'internalError' && this.jobDetails.stage.toString() === '3') {
            endpoint = 'exportPlatformProductsNotFound';
        } else if (type === 'notFound' && this.jobDetails.stage.toString() === '3') {
            endpoint = 'exportPlatformProductsInternalError';
        }

        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': endpoint,
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId']
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-xlsx-handler',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }).then(response => {
                if (response['errorType']) {
                    this.messageService.showSnack(response['errorMessage'], 'error')
                } else {
                    window.open(response['url'], '_blank');
                }
            })
    }

    remove(type = 'notFound') {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to remove this rows? <br>Removal is irreversible.`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                if (this.notFoundProducts === this.allResults && type === 'notFound' ||
                    this.productsToConfirm === this.allResults && type === 'notFound' ||
                    this.notFoundProducts === this.allResults && type === 'internal') {
                        setTimeout(() => {
                            this.dialog.open(ErrorComponent, {
                                data: {
                                    message: 'All rows have been removed! Job will be aborted!'
                                }
                            }).afterClosed().subscribe((response) => {
                                this.abort();
                                return;
                            });
                        }, 1000);
                }

                let deletedItems;

                if (this.jobDetails.stage.toString() === '3' && type === 'notFound') {
                    deletedItems = this.dataSource['filteredData'].filter(item => item['type'] === 'action-required');
                } else if (this.jobDetails.stage.toString() === '3' && type === 'internal') {
                    deletedItems = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found');
                } else if (this.jobDetails.stage.toString() === '2') {
                    deletedItems = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found');
                }

                const chunkSize = 300;
                for (let i = 0; i < deletedItems.length; i += chunkSize) {
                    const chunk = deletedItems.slice(i, i + chunkSize);

                    const data = []
                    for (let i = 0; i < chunk.length; i++) {
                        this.dataSource = new MatTableDataSource(this.dataSource['filteredData'].filter(item => item['row'] !== chunk[i]['row']));
                        data.push({
                            'id': chunk[i].rowId,
                            'event': 'delete'
                        });
                    }

                    this.jobJsonEdit(data).then(response => {
                        if (response && response.errorType) {
                            this.messageService.showSnack(response.errorMessage, 'error');
                        } else {
                            if (this.jobDetails.stage.toString() === '3' && type === 'notFound') {
                                this.productsToConfirm = this.dataSource['filteredData'].filter(item => item['type'] === 'action-require').length;
                            } else if (this.jobDetails.stage.toString() === '3' && type === 'internal') {
                                this.notFoundProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found').length;
                            } else {
                                this.notFoundProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found').length;
                            }
                            this.allResults = this.allResults - chunk.length;

                            if (this.allResults === 0) {
                                setTimeout(() => {
                                    this.dialog.open(ErrorComponent, {
                                        data: {
                                            message: 'All rows have been removed! Job will be aborted!'
                                        }
                                    }).afterClosed().subscribe((response) => {
                                        this.abort();
                                        return;
                                    });
                                }, 1000);
                            }
                        }
                    });
                }
            }
        });
    }

    retry(type) {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to retry gathering?`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                this.isLoading = true;
                this.jobProcessingTrigger(true, type).then(result => {
                    if (result && result.errorType) {
                        this.messageService.showSnack(result.errorMessage, 'error');
                    } else {
                        this.isRetry.emit(true)
                    }
                });
            }
        });
    }

    getPlatformData() {
        this.isLoading = true;
        this.jobProcessingTrigger().then(response => {
            if (response && response.errorType) {
                this.messageService.showSnack(response.errorMessage, 'error');
            } else {
                this.isJobJsonEditFinished.emit(true)
            }
        });
    }

    importToTracker() {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to import to tracker?`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                this.isLoading = true;
                this.initTrackerImport().then(response => {
                    if (response && response.errorType) {
                        this.messageService.showSnack(response.errorMessage, 'error');
                        this.isLoading = false;
                    } else {
                        this.isPlatformDataEditFinished.emit(true)
                    }
                });
            }
        });
    }

    jobJsonEdit(rows): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'jobJsonEdit',
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId'],
                            'rows': rows
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }
        )
    }

    updateSelectedCellsValues(text: string, object = {}, cellType = null) {
        if (this.tableMouseDown && this.tableMouseUp) {
            const dataCopy: Element[] = this.dataSource['filteredData'].slice();
            let startCol: number;
            let endCol: number;
            let startRow: number;
            let endRow: number;

            if (this.tableMouseDown.colId <= this.tableMouseUp.colId) {
                startCol = this.tableMouseDown.colId;
                endCol = this.tableMouseUp.colId;
            } else {
                endCol = this.tableMouseDown.colId;
                startCol = this.tableMouseUp.colId;
            }

            if (this.tableMouseDown.rowId <= this.tableMouseUp.rowId) {
                startRow = this.tableMouseDown.rowId;
                endRow = this.tableMouseUp.rowId;
            } else {
                endRow = this.tableMouseDown.rowId;
                startRow = this.tableMouseUp.rowId;
            }
            if (this.jobDetails.stage.toString() === '3') {
                const i = dataCopy.findIndex(item => item['row'] === object['row'])
                dataCopy[i][cellType] = text;
            }

            if (this.selectedValues && this.jobDetails.stage.toString() === '2') {
                if (this.tableMouseDown.cellsType === this.tableMouseUp.cellsType) {
                    for (let i = startRow; i <= endRow; i++) {
                        dataCopy[i][this.tableMouseUp.cellsType] = dataCopy[i]['type'] !== 'not-found' ? this.selectedValues[this.tableMouseUp.cellsType] : '';
                        const index = this.jsonResult.findIndex(item => item['rowNumber'] === dataCopy[i]['row'])
                        const updatedItem = this.jsonResult[index]
                        if (this.tableMouseUp.cellsType === 'brand') {
                            this.updatedJSON.push({
                                'id': updatedItem['id'],
                                'event': 'update',
                                'brand': dataCopy[i][this.tableMouseUp.cellsType]
                            })
                        } else if (this.tableMouseUp.cellsType === 'subCategory') {
                            this.updatedJSON.push({
                                'id': updatedItem['id'],
                                'event': 'update',
                                'subCategory': dataCopy[i][this.tableMouseUp.cellsType]
                            })
                        }
                        if ((dataCopy[i]['brand'] === '' || dataCopy[i]['subCategory'] === '') && dataCopy[i]['type'] !== 'not-found') {
                            dataCopy[i]['type'] = 'action-required'
                        } else {
                            if (dataCopy[i].brand !== '' && dataCopy[i].subCategory !== '' && dataCopy[i].type === 'action-required') {
                                dataCopy[i].type = updatedItem.meta.tracker.gathered === true && updatedItem.meta.tracker.found === true ? 'tracker' : 'new';
                            }
                        }
                    }
                } else {
                    for (let i = startRow; i <= endRow; i++) {
                        dataCopy[i][this.tableMouseUp.cellsType] = dataCopy[i]['type'] !== 'not-found' ? this.selectedValues[this.tableMouseUp.cellsType] ? this.selectedValues[this.tableMouseUp.cellsType] :
                            dataCopy[i][this.tableMouseUp.cellsType] : '';
                        dataCopy[i][this.tableMouseDown.cellsType] = dataCopy[i]['type'] !== 'not-found' ? this.selectedValues[this.tableMouseDown.cellsType] ? this.selectedValues[this.tableMouseDown.cellsType] :
                            dataCopy[i][this.tableMouseDown.cellsType] : '';
                        const index = this.jsonResult.findIndex(item => item['rowNumber'] === dataCopy[i]['row'])
                        const updatedItem = this.jsonResult[index]
                        this.updatedJSON.push({
                            'id': updatedItem['id'],
                            'event': 'update',
                            'brand': dataCopy[i]['brand'],
                            'subCategory': dataCopy[i]['subCategory']
                        })
                        if ((dataCopy[i]['brand'] === '' || dataCopy[i]['subCategory'] === '') && dataCopy[i]['type'] !== 'not-found') {
                            dataCopy[i]['type'] = 'action-required'
                        } else {
                            if (dataCopy[i].brand !== '' && dataCopy[i].subCategory !== '' && dataCopy[i].type === 'action-required') {
                                dataCopy[i].type = updatedItem.meta.tracker.gathered === true && updatedItem.meta.tracker.found === true ? 'tracker' : 'new';
                            }
                        }
                    }
                }
                if (this.updatedJSON && this.updatedJSON.length > 0) {
                    this.jobJsonEdit(this.updatedJSON).then(response => {
                        if (response && response.errorType) {
                            this.messageService.showSnack(response.errorMessage, 'error');
                        } else {

                        }
                    }).then(() => {
                        this.dataSource['filteredData'] = dataCopy;
                        this.trackerProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'tracker').length;
                        this.newProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'new').length;
                        this.productsToConfirm = this.dataSource['filteredData'].filter(item => item['type'] === 'action-required').length;
                        this.notFoundProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found').length;
                    });
                    this.updatedJSON = [];
                }
            } else if (this.jobDetails.stage.toString() === '3' && object) {
                const dataCopyIndex = dataCopy.findIndex(item => item['row'] === object['row'])
                const index = this.jsonResult.findIndex(item => item['rowNumber'] === dataCopy[dataCopyIndex]['row'])
                const updatedItem = this.jsonResult[index]
                if (updatedItem[cellType] !== text) {
                    if (cellType === 'url' && this.isValidURL(text)) {
                        this.updatedJSON.push({
                            'id': updatedItem['id'],
                            'event': 'update',
                            'url': text
                        })
                    } else if (cellType === 'rpc' && this.isDuplicatedRpc(text)) {
                        this.updatedJSON.push({
                            'id': updatedItem['id'],
                            'event': 'update',
                            'rpc': text
                        })
                    }
                    if (this.updatedJSON && this.updatedJSON.length > 0) {
                        this.jobJsonEdit(this.updatedJSON).then(response => {
                            if (response && response.errorType) {
                                this.messageService.showSnack(response.errorMessage, 'error');
                            } else {
                                if (dataCopy[dataCopyIndex].rpc !== '' && dataCopy[dataCopyIndex].url !== '' && dataCopy[dataCopyIndex].rpc !== '-' && dataCopy[dataCopyIndex].url !== '-' &&
                                    (dataCopy[dataCopyIndex].type === 'action-required' || dataCopy[dataCopyIndex].type === 'not-found')) {
                                    dataCopy[dataCopyIndex].type = updatedItem['platformData'] ? 'new' : 'tracker';
                                }
                            }
                        }).then(() => {
                            this.dataSource['filteredData'] = dataCopy;
                            this.trackerProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'tracker').length;
                            this.newProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'new').length;
                            this.productsToConfirm = this.dataSource['filteredData'].filter(item => item['type'] === 'action-required').length;
                            this.notFoundProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found').length;
                        });
                    }
                    this.updatedJSON = [];
                }
            }
            this.dataSource['filteredData'] = dataCopy;
            this.trackerProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'tracker').length;
            this.newProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'new').length;
            this.productsToConfirm = this.dataSource['filteredData'].filter(item => item['type'] === 'action-required').length;
            this.notFoundProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found').length;
        }
    }

    tableSort(event) {
        for (let i = this.FIRST_EDITABLE_ROW; i <= this.LAST_EDITABLE_ROW; i++) {
            for (let j = this.FIRST_EDITABLE_COL; j <= this.LAST_EDITABLE_COL; j++) {
                this.selectedCellsState[i][j] = false;
            }
        }
    }

    onMouseDown(rowId: number, colId: number, cellsType: string, event) {
        this.tableMouseDown = {rowId: rowId, colId: colId, cellsType: cellsType};
    }

    onMouseUp(rowId: number, colId: number, cellsType: string) {
        this.tableMouseUp = {rowId: rowId, colId: colId, cellsType: cellsType};
        if (this.tableMouseDown) {
            this.newCellValue = '';
            this.updateSelectedCellsState(this.tableMouseDown.colId, this.tableMouseUp.colId, this.tableMouseDown.rowId, this.tableMouseUp.rowId);
        }
    }

    inputChange(event, element, type) {
        this.updateSelectedCellsValues(event.target.value, element, type);
    }

    updateSelectedCellsState(mouseDownColId: number, mouseUpColId: number, mouseDownRowId: number, mouseUpRowId: number) {
        for (let i = this.FIRST_EDITABLE_ROW; i <= this.LAST_EDITABLE_ROW; i++) {
            for (let j = this.FIRST_EDITABLE_COL; j <= this.LAST_EDITABLE_COL; j++) {
                this.selectedCellsState[i][j] = false;
            }
        }
        // update selected cells
        let startCol: number;
        let endCol: number;
        let startRow: number;
        let endRow: number;
        if (mouseDownColId <= mouseUpColId) {
            startCol = mouseDownColId;
            endCol = mouseUpColId;
        } else {
            endCol = mouseDownColId;
            startCol = mouseUpColId;
        }

        if (mouseDownRowId <= mouseUpRowId) {
            startRow = mouseDownRowId;
            endRow = mouseUpRowId;
        } else {
            endRow = mouseDownRowId;
            startRow = mouseUpRowId;
        }

        this.isMultiSelection = (startRow !== endRow || startCol !== endCol)
        for (let i = startRow; i <= endRow; i++) {
            for (let j = startCol; j <= endCol; j++) {
                this.selectedCellsState[i][j] = true;
            }
        }
    }

    @HostListener('document:keydown', ['$event'])
    onKeyUp(event: KeyboardEvent): void {
        if (this.tableMouseDown && this.tableMouseUp) {

            const specialKeys: string[] = ['Enter', 'PrintScreen', 'Escape', 'cControl', 'NumLock', 'PageUp', 'PageDown', 'End',
                'Home', 'Delete', 'Insert', 'ContextMenu', 'Control', 'ControlAltGraph', 'Alt', 'Meta', 'Shift', 'CapsLock',
                'TabTab', 'ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp', 'Pause', 'ScrollLock', 'Dead', '',
                'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'];

            if (event.key === 'Backspace') {
                const end: number = this.newCellValue.length - 1;
                this.newCellValue = this.newCellValue.slice(0, end);

            // } else if (this.indexOfInArray(event.key, specialKeys) === -1) {
            //     this.newCellValue += event.key;
            }
            // if (event.metaKey === false) {
            //     this.updateSelectedCellsValues(this.newCellValue);
            // }
        }

        if (event.key === 'c' && event.metaKey === true) {
            this.selectedValues = {
                'brand': '',
                'subCategory': ''
            };

            if (this.tableMouseDown) {
                this.selectedValues[this.tableMouseDown.cellsType] = this.dataSource['filteredData'][this.tableMouseDown.rowId][this.tableMouseDown.cellsType]
            }
            if (this.tableMouseUp) {
                this.selectedValues[this.tableMouseUp.cellsType] = this.dataSource['filteredData'][this.tableMouseUp.rowId][this.tableMouseUp.cellsType]
            }
        }
        if (event.key === 'v' && event.metaKey === true) {
            if (this.jobDetails.stage.toString() === '2') {
                if (this.selectedValues['brand'] !== '' || this.selectedValues['subCategory'] !== '') {
                    this.updateSelectedCellsValues(null);
                } else {
                    navigator['clipboard'].readText().then(data => {
                        if (data) {
                            this.updateSelectedCellsValues(data);
                        }
                    })
                }
            // } else {
            //     navigator['clipboard'].readText().then(data => {
            //         if (data) {
            //             this.updateSelectedCellsValues(data);
            //         }
            //     })
            }
        }
    }

    indexOfInArray(item: string, array: string[]): number {
        let index = -1;
        for (let i = 0; i < array.length; i++) {
            if (array[i] === item) {
                index = i;
            }
        }
        return index;
    }

    prepareData() {
        const ELEMENT_DATA: Element[] = [];
        this.jsonResult.forEach(item => {
            let type = '';
            let previewUrl = '';
            if (this.jobDetails.stage.toString() === '2') {
                if (item.meta) {
                    if (item.meta.tracker.gathered === true && item.meta.tracker.found === true) {
                        type = 'tracker'
                    } else if (item.meta.tracker.gathered === true && item.meta.tracker.found === false && item.meta.browser.gathered === true && item.meta.browser.found === true) {
                        type = 'new'
                    } else {
                        type = 'not-found'
                    }

                    if ((item['brand'] === '' || item['subCategory'] === '') && type !== 'not-found') {
                        type = 'action-required'
                    }
                }
            } else {
                    if (item.meta && item.meta.platform && item.meta.platform.error && item.meta.platform.error.type !== 'ProductNotFoundException' && ((!item.rpc || item.rpc === '') || (!item.url || item.url === ''))) {
                        type = 'not-found'
                    } else if (item.meta && item.meta.platform && item.meta.platform.error && item.meta.platform.error.type === 'ProductNotFoundException' && ((!item.rpc || item.rpc === '') || (!item.url || item.url === ''))) {
                        type = 'action-required'
                    } else if (item.platformData) {
                        type = 'new'
                    } else if (item.trackerData) {
                        type = 'tracker'
                    }
            }

            if (item.browserData && item.browserData.localization && item.browserData.localization.previewUrl) {
                previewUrl = item.browserData.localization.previewUrl
            }

            ELEMENT_DATA.push({
                type: type,
                platform: this.platformSlug,
                ean: item['ean'] ? item['ean'] : '-',
                fpc: item['fpc'] ? item['fpc'] : '-',
                subCategory: item['subCategory'],
                brand: item['brand'],
                name: item['productName'] ? item['productName'] : '-',
                powersku: item['powersku'] ? item['powersku'] : '-',
                rpc: item['rpc'] ? item['rpc'] : '-',
                url: item['url'] ? item['url'] : '-',
                row: item['rowNumber'],
                rowId: item['id'],
                previewUrl: previewUrl ? previewUrl : null
            })
            this.selectedCellsState.push([false, false])
        })

        this.trackerProducts = ELEMENT_DATA.filter(item => item.type === 'tracker').length;
        this.newProducts = ELEMENT_DATA.filter(item => item.type === 'new').length;
        this.productsToConfirm = ELEMENT_DATA.filter(item => item.type === 'action-required').length;
        this.notFoundProducts = ELEMENT_DATA.filter(item => item.type === 'not-found').length;
        this.dataSource = new MatTableDataSource(ELEMENT_DATA)
        this.isTableLoading = false;
    }

    openPreview(element) {
        window.open('http:' + element.previewUrl, '_blank')
    }

    updateJson(element, type, event) {
        const index = this.jsonResult.findIndex(item => item['rowNumber'] === element['row'])
        const updatedItem = this.jsonResult[index]
        const data = [];
        if (type === 'brand') {
            data.push({
                'id': updatedItem['id'],
                'event': 'update',
                'brand': event.value
            })
        } else if (type === 'subCategory') {
            data.push({
                'id': updatedItem['id'],
                'event': 'update',
                'subCategory': event.value
            })
        }
        element[type] = event.value

        this.jobJsonEdit(data).then(response => {
            if (response && response.errorType) {
                this.messageService.showSnack(response.errorMessage, 'error');
            } else {
                if (element.subCategory !== '' && element.brand !== '' && element.type === 'action-required') {
                    element.type = updatedItem['meta']['tracker'].found ? 'tracker' : 'new';
                }
                this.trackerProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'tracker').length;
                this.newProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'new').length;
                this.productsToConfirm = this.dataSource['filteredData'].filter(item => item['type'] === 'action-required').length;
                this.notFoundProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found').length;
            }
        });
    }

    deleteRow(element) {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to remove this row? <br>Removal is irreversible.`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                this.dataSource = new MatTableDataSource(this.dataSource['filteredData'].filter(item => item['row'] !== element['row']));
                const deletedItem = this.jsonResult.filter(item => item['rowNumber'] === element['row']);
                const data = []
                data.push({
                    'id': deletedItem[0].id,
                    'event': 'delete'
                })

                this.jobJsonEdit(data).then(result => {
                    if (result && result.errorType) {
                        this.messageService.showSnack(result.errorMessage, 'error');
                    } else {
                        this.notFoundProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found').length
                        this.productsToConfirm = this.dataSource['filteredData'].filter(item => item.type === 'action-required').length;
                        this.jsonResult = this.jsonResult.filter(row => row['id'] !== deletedItem[0].id)
                        this.allResults--;

                        if (this.allResults === 0) {
                            setTimeout(() => {
                                this.dialog.open(ErrorComponent, {
                                    data: {
                                        message: 'All rows have been removed! Job will be aborted!'
                                    }
                                }).afterClosed().subscribe((response) => {
                                    this.abort();
                                });
                            }, 1000);
                        }
                    }
                });
            }
        });
    }

    canGetPlatformData() {
        const emptyBrands = this.dataSource['filteredData'].filter(item => item['brand'] === '').length
        const emptySubCategories = this.dataSource['filteredData'].filter(item => item['subCategory'] === '').length
        return this.notFoundProducts === 0 && this.productsToConfirm === 0 && this.dataSource['filteredData'] && this.dataSource['filteredData'].length > 0 && emptyBrands === 0 && emptySubCategories === 0;
    }

    canImport() {
        return this.notFoundProducts === 0 && this.productsToConfirm === 0 && this.dataSource['filteredData'] && this.dataSource['filteredData'].length > 0 && this.countValidationErrors() === true;
    }

    jobProcessingTrigger(retry = false, type = 'notFound'): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': retry ? 'jobPlatformProcessingRetryTrigger' : 'jobPlatformProcessingTrigger',
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId'],
                            'refreshUrl': this.refreshUrl
                        }
                    }
                }
                if (retry) {
                    lambdaOptions['action']['data']['retryByErrorType'] = String(type);
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                })
            }
        )
    }

    makeAbort() {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to abort? <br>It is irreversible.`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                this.abort();
            }
        })
    }

    abort() {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'jobAbort',
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId']
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }).then(response => {
            if (response['errorType']) {
                this.messageService.showSnack(response['errorMessage'], 'error')
            } else {
                this.messageService.showSnack('Job aborted', 'info')
                this.backToImports();
            }
        })
    }

    backToImports() {
        this.router.navigate(['/']);
        this.isLoading = true;
    }

    initTrackerImport(): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'initTrackerImport',
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId']
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-xlsx-handler',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }
        )
    }

    isValidURL(string) {
        const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    };

    isDuplicatedRpc(text) {
        const duplicates = this.dataSource['filteredData'].filter(item => item['rpc'] && item['rpc'].toString().trim() === text.toString().trim())
        return duplicates.length === 1;
    }

    countValidationErrors() {
        const rpcErrors = document.querySelectorAll('.rpc.false');
        const urlErrors = document.querySelectorAll('.link.false');
        const emptyUrls = this.dataSource['filteredData'].filter(item => item['url'] === '').length
        const emptyRpc = this.dataSource['filteredData'].filter(item => item['rpc'] === '' || item['rpc'] === '-').length
        return (rpcErrors.length + urlErrors.length + emptyUrls + emptyRpc) === 0;
    }
}